import { createAsyncThunk } from '@reduxjs/toolkit';
// import { processThunkError } from '@utils/processThunkError';
import { genericTokenCheck } from '@containers/App/state/thunks';

import SurveyPageAPIs, { getPublicReportCountType } from './apis';

export const getPublicReportCountThunk = createAsyncThunk(
  'homePage/getPublicReportCount',
  async (payload: getPublicReportCountType, { dispatch }) => {
    // rejectWithValue }) => {
    let token = null;
    try {
      token = await genericTokenCheck(dispatch, true);
    } catch {
      //
    }

    let results;
    try {
      results = await SurveyPageAPIs.getPublicReportCountAPI(token, payload);
    } catch (err: any) {
      // const errorReject = await processThunkError(err, dispatch, 'HOME_PAGE');
      // return rejectWithValue(errorReject);
      return { count: 0 };
    }

    return results;
  },
);
