/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPublicReportCountResponseType } from './apis';

import { getPublicReportCountThunk } from './thunks';

export interface HomePageState {
  pending: boolean;
  publicReportCount: number;
}

const initialState: HomePageState = {
  pending: false,
  publicReportCount: 0,
};

const slice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getPublicReportCountThunk.pending,
      (state: HomePageState) => {
        state.pending = true;
      },
    );
    builder.addCase(
      getPublicReportCountThunk.fulfilled,
      (
        state: HomePageState,
        action: PayloadAction<getPublicReportCountResponseType>,
      ) => {
        state.pending = false;
        state.publicReportCount = action.payload.count || 0;
      },
    );
    builder.addCase(
      getPublicReportCountThunk.rejected,
      (state: HomePageState) => {
        state.pending = false;
      },
    );
  },
});

// export const {} = slice.actions;

export default slice.reducer;
