import axios from 'axios';
import { HTTP_API_URL } from '@constants/endpoints';
import { apiErrorProcessing } from '@constants/error_messages';

import {} from './reducer';

export type ReportTypes = 'BASIC' | 'CUSTOM';

export interface getPublicReportCountType {}

export interface getPublicReportCountResponseType {
  count: number;
}

export default class SurveyPageAPIs {
  static getPublicReportCountAPI(
    token: string | null,
    payload: getPublicReportCountType,
  ) {
    return axios
      .post(
        `${HTTP_API_URL}/survey/${
          token ? 'getPublicReportCount' : 'getPublicReportCount'
        }`,
        payload,
        token
          ? {
              headers: { Authorization: `Bearer ${token}` },
            }
          : {},
      )
      .then((result: { data: getPublicReportCountResponseType }) => result.data)
      .catch(apiErrorProcessing);
  }
}
